import React from 'react'
import PropTypes from 'prop-types'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopSharpIcon from '@material-ui/icons/StopSharp'
import { ButtonContainer } from './styles'
import { PLAY, STOP } from './modes'

const iconsMapper = {
  [PLAY]: PlayArrowIcon,
  [STOP]: StopSharpIcon,
}

const PlayStopButton = ({ isRunning, onClick, isDisabled }) => {
  const mode = isRunning ? STOP : PLAY
  const Icon = iconsMapper[mode]
  const iconColor = isDisabled ? 'rgba(0, 0, 0, 0.26)' : 'white'
  return (
    <ButtonContainer mode={mode} onClick={onClick} disabled={isDisabled} style={{backgroundColor:'#050519'}}>
      <Icon fontSize="large" htmlColor={iconColor} />
    </ButtonContainer>
  )
}

PlayStopButton.propTypes = {
  isRunning: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default PlayStopButton
