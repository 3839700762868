import styled from 'styled-components'
import { TextField, Grid } from '@material-ui/core'

export const Container = styled.div``

export const PickerContainer = styled.div`
  & input {
    color: #666;
  }

  & input[type='time']::-webkit-clear-button {
    display: none;
  }
  &:hover {
    border-bottom: 0.5px solid #A2A2FF;
  }
`

export const DurationPickerContainer = styled(PickerContainer)`
  width: ${({ width }) => width || '50%'};
  padding: ${({ padding }) => padding || '0 5px'};
  min-width: 84px;
`

export const TimePickerContainer = styled(PickerContainer)`
  width: ${({ width }) => width || '50%'};
  padding: ${({ padding }) => padding || '0 5px'};
  min-width: ${({ minWidth }) => minWidth || '84px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '6px'};
  font-size: 1rem;
`

export const PickerIconWrapper = styled.a`
  padding: 5px;
  color: #999;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.08);
  }
`

export const CustomTextField = styled(TextField)`
  input {
    text-align: ${({ textalign }) => textalign || 'left'} !important;
  }
  p {
    text-align: ${({ textalign }) => textalign || 'left'} !important;
  }
`
export const List = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 100%;
  top: 0;
  left: 0;
  padding: 0px 15px;
  margin: 0;
  overflow-y: auto;
`

export const ListContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

export const ListViewContainer = styled(Grid)`
  display: flex;
  overflow: hidden;
`
