import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Blue, LilacText } from '../../../../styledComponents/text'
import eventUtils from '../../../../utils/eventUtils'

const TimesheetInfoItem = ({ events, title, showZero }) => {
  if (_.isEmpty(events) && !showZero) return null
  const projectsCount = events.length
  const projectsDuration = eventUtils.getTotalDurationInHours(events)
  return (
    <span>
      {title}: <LilacText>{`${projectsDuration}h(${projectsCount}) `}</LilacText>
    </span>
  )
}

TimesheetInfoItem.propTypes = {
  events: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  showZero: PropTypes.bool,
}

TimesheetInfoItem.defaultProps = {
  showZero: false,
}

export default TimesheetInfoItem
