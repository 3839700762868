import React from 'react'
import PropTypes from 'prop-types'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import eventUtils from '../../../utils/eventUtils'
import { DeleteButton, ActionButton } from '../ListView/EntryBoxEditStyle'
import { isAnOperation, getEntry } from '../../../models/entryOperation'

const ACTION_BUTTON_LABELS = { CREATE: 'create', UPDATE: 'update' }

const EntryBoxButtons = ({
  entry: entryOrOperation,
  onEntryDelete,
  classes,
  onSubmit,
  isSubmitBlocked,
}) => {
  const isOperation = isAnOperation(entryOrOperation)
  const entry = isOperation ? getEntry(entryOrOperation) : entryOrOperation
  const isPlaceholderEntry = eventUtils.isPlaceholderEntry(entry)
  const buttonLabel = isPlaceholderEntry ? ACTION_BUTTON_LABELS.CREATE : ACTION_BUTTON_LABELS.UPDATE

  return (
    <>
      {!isPlaceholderEntry && (
        <DeleteButton id="delete-button" onClick={() => onEntryDelete(entryOrOperation)}>
          <DeleteRoundedIcon color="error" className={classes.deleteIcon} />
        </DeleteButton>
      )}
      <ActionButton
        data-testid={`entryBox.actionButton.${buttonLabel}`}
        id="create-button"
        size="medium"
        variant="contained"
        onClick={onSubmit}
        disabled={isSubmitBlocked}
        style={{backgroundColor:'#050519', color:'#ffffff'}}
      >
        {isPlaceholderEntry ? <AddCircleOutlineIcon /> : <EditIcon />}
      </ActionButton>
    </>
  )
}

EntryBoxButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEntryDelete: PropTypes.func.isRequired,
  isSubmitBlocked: PropTypes.bool.isRequired,
}

export default EntryBoxButtons
