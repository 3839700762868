import styled from 'styled-components'
import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'

export const Small = styled.span`
  font-size: 0.8rem;
`

export const Medium = styled.span`
  font-size: 1rem;
`

export const Blue = styled.span`
  color: ${blue[500]};
`
export const LilacText = styled.span`
  color: #A2A2FF;
`

export const White = styled.span`
  color: white;
`

export const Red = styled.span`
  color: ${props => red[props.tone || 500]};
`

export const SmallGrey = styled(Small)`
  color: ${props => grey[props.tone || 500]};
`
export const SmallRed = styled(Small)`
  color: ${props => red[props.tone || 500]};
`

export const MarginLeft = styled.div`
  margin-left: ${props => props.left}px;
`
