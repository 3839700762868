import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import CustomTooltip from '../../../components/CustomTooltip'

import { styles, TimesheetTooltip } from './styles'
import { APPROVED, TIMESHEET_STATUS } from '../../../constants/timesheetStatus'
import { hasLocalOperations } from '../../../models/entryOperation'

import { isTimesheetAbleToBeReopened } from '../../../utils/appUtils'

const REOPEN = 'Reopen'
const SUBMIT = 'Submit'
const SAVE = 'Save'

const SubmissionToolbar = props => {
  const [isActionButtonDisabled, setActionButtonAsDisabled] = useState(false)
  const doesTimesheetHasLocalOperations = hasLocalOperations(
    props.uiRefreshTimePeriod,
    props.localEntries,
  )
  const renderButtonText = () => {
    if (doesTimesheetHasLocalOperations) return SAVE

    if (!props.isDayViewPeriodSelected && props.areAllTimesheetsSubmitted) return REOPEN
    if (props.isDayViewPeriodSelected && props.timesheet.approvalStatus === APPROVED) return REOPEN
    return SUBMIT
  }

  const isReopenDisabled = () => {
    return (
      props.isDayViewPeriodSelected &&
      props.timesheet.approvalStatus === APPROVED &&
      !isTimesheetAbleToBeReopened(props.timesheet)
    )
  }

  const getDelegateData = () => {
      return props.delegateAccessList?.find(delegate => delegate._id === props.selectedDelegateId) ?? {}
  }

  const isExpert = () => {
    const { position, employeeType } = props.person
    const { fromPosition, fromEmployeeType } = getDelegateData()
    return props.selectedDelegateId ? 
    [fromPosition, fromEmployeeType].includes('Contractor - Expert') :
    [position, employeeType].includes('Contractor - Expert')
  }

  const shouldBeSubmissionButtonBlocked = () => {
    if (doesTimesheetHasLocalOperations && props.isConnected) return false
    if (_.isEmpty(props.timesheet)) return true
    if (props.isFetchingTimesheetData) return true
    if (!props.isConnected) return true
    if (props.isSubmitInProgress) return true
    if (isReopenDisabled()) return true
    if (props.isDayViewPeriodSelected && !props.hasTimesheetUnassignedEntries) return false
    if (props.isDayViewPeriodSelected && props.timesheet.approvalStatus === APPROVED) return false
    if (
      !props.isDayViewPeriodSelected &&
      !props.hasWeekTimesheetsUnassignedEntries &&
      (props.areAllTimesheetsWithEntries || (isExpert() && !props.areAllTimesheetsWithoutEntries))
    )
      return false
    return true
  }

  const getButtonHandler = () => {
    if (doesTimesheetHasLocalOperations) return props.saveLocalEntryOperations()
    return props.handleTimesheetSubmission()
  }

  const getTooltipData = () => {

    const tooltip = { disabled: false }

    if (_.isEmpty(props.timesheet)) return { ...tooltip, text: TIMESHEET_STATUS.ERROR }
    if (props.isFetchingTimesheetData) return { ...tooltip, text: TIMESHEET_STATUS.FETCHING }
    if (!props.isConnected) return { ...tooltip, text: TIMESHEET_STATUS.CONNECTION }
    if (props.isSubmitInProgress && props.timesheet.approvalStatus === APPROVED)
      return { ...tooltip, text: TIMESHEET_STATUS.REOPEN }
    if (props.isSubmitInProgress) return { ...tooltip, text: TIMESHEET_STATUS.SUBMITTED }
    if (isReopenDisabled()) return { ...tooltip, text: TIMESHEET_STATUS.EARLIER }
    if (props.isDayViewPeriodSelected && props.hasTimesheetUnassignedEntries)
      return { ...tooltip, text: TIMESHEET_STATUS.UNASSIGNED }

    if (
      !props.isDayViewPeriodSelected &&
      !props.hasWeekTimesheetsUnassignedEntries &&
      ( !props.areAllTimesheetsWithEntries && !isExpert() )
    )
      return { ...tooltip, text: TIMESHEET_STATUS.WEEK_VIEW }

    return { text: '', disabled: true }
  }

  const renderActionContent = () => {
    const buttonText = renderButtonText()
    const tooltip = getTooltipData()

    return (
      <TimesheetTooltip
        title={tooltip.text}
        disableHoverListener={tooltip.disabled}
        disableFocusListener={tooltip.disabled}
        disableTouchListener={tooltip.disabled}
      >
        <div className={props.classes.wrapper}>
          <Button
            data-testid={`toolbarAction.${buttonText}`}
            variant="contained"
            color="primary"
            size="small"
            disabled={shouldBeSubmissionButtonBlocked() || isActionButtonDisabled}
            className={props.classes.bootstrapRoot}
            style={{backgroundColor:'#5856FF'}}
            onClick={async () => {
              setActionButtonAsDisabled(true)
              await getButtonHandler()
              setActionButtonAsDisabled(false)
            }}
            tabIndex={props.tabIndex}
          >
            {buttonText}
          </Button>
          {props.isSubmitInProgress && (
            <CircularProgress size={18} className={props.classes.buttonProgress} />
          )}
        </div>
      </TimesheetTooltip>
    )
  }

  const getTooltipMessage = () => {
    if (isReopenDisabled()) return 'Reopen not allowed on this date'
    if (doesTimesheetHasLocalOperations)
      return 'Once the network is restored, you will be able to save and submit your timesheet'
  }

  const renderTooltip = () => {
    const tooltipMessage = getTooltipMessage()
    return (
      <CustomTooltip
        title={tooltipMessage}
        textAlign={doesTimesheetHasLocalOperations ? 'left' : 'center'}
      >
        {renderActionContent()}
      </CustomTooltip>
    )
  }

  const renderTimesheetAction = () => {
    if (_.isNil(props.timesheet)) return null

    if (isReopenDisabled() || (doesTimesheetHasLocalOperations && !props.isConnected))
      return renderTooltip()
    return renderActionContent()
  }

  if (_.isNil(props.timesheet)) return null
  return (
    <div className="status-container--submission">
      <div className="status-submit-item">{renderTimesheetAction()}</div>
    </div>
  )
}

SubmissionToolbar.propTypes = {
  hasTimesheetUnassignedEntries: PropTypes.bool.isRequired,
  hasWeekTimesheetsUnassignedEntries: PropTypes.bool.isRequired,
  timesheet: PropTypes.object.isRequired,
  isSubmitInProgress: PropTypes.bool.isRequired,
  handleTimesheetSubmission: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    bootstrapRoot: PropTypes.string,
    buttonProgress: PropTypes.string,
    wrapper: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tabIndex: PropTypes.string.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  areAllTimesheetsSubmitted: PropTypes.bool.isRequired,
  areAllTimesheetsWithEntries: PropTypes.bool.isRequired,
  areAllTimesheetsWithoutEntries: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  localEntries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  uiRefreshTimePeriod: PropTypes.shape({
    startISO: PropTypes.string.isRequired,
    endISO: PropTypes.string.isRequired,
  }).isRequired,
  saveLocalEntryOperations: PropTypes.func.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
  selectedDelegateId: PropTypes.string,
  delegateAccessList: PropTypes.array,
}

export default withStyles(styles)(SubmissionToolbar)
