import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DurationPickerContainer, CustomTextField } from '../../ListView/style'

const addDecimalDot = strNumber => {
  if (typeof strNumber !== 'string') return strNumber
  if (strNumber.startsWith('0') && !strNumber.startsWith('0.') && strNumber.length > 1) {
    return `${strNumber.slice(0, 1)}.${strNumber.slice(1)}`
  }
  return strNumber
}

const DurationPickerInput = ({ width, value, onChange, ...props }) => {
  const [controlledValue, setControlledValue] = useState(value)
  const [wasPreviousCharDot, setPreviousCharAsDot] = useState(false)

  useEffect(() => {
    setControlledValue(value)
  }, [value])

  const handleChange = ({ target }) => {
    const valueFormatted = addDecimalDot(target.value)
    const numberCheck = checkValidDecimalNumber(valueFormatted)
    const validation24h = parseFloat(numberCheck) > 24 ? '24' : numberCheck
    onChange(validation24h, setControlledValue)
  }

  const checkValidDecimalNumber = strNumber => {
    const strNumberArray = strNumber.split('.')
    if (strNumberArray.length > 1) {
      const isValid = strNumberArray[1].length < 2
      const number = isValid ? strNumber : `${strNumberArray[0]}.${strNumberArray[1].slice(0, 1)}`
      return number
    }
    return strNumber
  }

  const onKeyPress = e => {
    const isPressedKeyDot = e.key === '.'
    const isInputTextSelected =
      window.getSelection && window.getSelection().toString() === controlledValue
    const includesDot = typeof controlledValue === 'string' && controlledValue.includes('.')
    if (
      (isPressedKeyDot && wasPreviousCharDot && controlledValue !== '') ||
      (includesDot && !isInputTextSelected)
    ) {
      e.preventDefault()
    }
    setPreviousCharAsDot(isPressedKeyDot)
  }

  const handleClick = ({ target }) => {
    const selection = window.getSelection && window.getSelection()
    const selectedText = selection ? selection.toString() : ''
    if (!target.select || (selectedText && selectedText.length > 0)) return
    target.select()
  }

  return (
    <DurationPickerContainer width={width}>
      <CustomTextField
        label="Duration"
        type="number"
        fullWidth
        InputLabelProps={{ shrink: true, style:{color:'#A2A2FF'} }}
        value={controlledValue}
        onChange={handleChange}
        onClick={handleClick}
        onKeyPress={onKeyPress}
        {...props}
      />
    </DurationPickerContainer>
  )
}

DurationPickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  InputProps: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.string.isRequired,
  textalign: PropTypes.string.isRequired,
}

DurationPickerInput.defaultProps = {
  inputProps: {},
}

export default memo(DurationPickerInput)
