import React, { PureComponent } from 'react'
import Mousetrap from 'mousetrap'
import PropTypes from 'prop-types'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import InputAdornment from '@material-ui/core/InputAdornment'
import Fuse from 'fuse.js'

import SuggestionList from './SuggestionsList'
import { Container, ExpandIconWrapper, ClearButton } from './styles'
import { keysCodes } from '../../../../../utils/keyboard'
import { isSuggestionPTO } from '../../../../../utils/appUtils'

class InputAutoSuggestion extends PureComponent {
  containerRef = React.createRef()

  suggestionContainerRef = null

  state = {
    value: '',
    visible: false,
  }

  componentDidMount() {
    this.setState({
      value: this.props.initialValue,
      initialValue: this.props.initialValue,
      currentValue: this.props.currentValue,
    })
    this.hideSuggestions()
    Mousetrap.bind('tab', () => {
      this.hideSuggestions()
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentValue !== state.currentValue) {
      return {
        value: props.currentValue,
        currentValue: props.currentValue,
      }
    }
    if (props.initialValue !== state.initialValue && props.currentValue === '') {
      return {
        value: props.initialValue,
        initialValue: props.initialValue,
      }
    }
    return null
  }

  componentWillUnmount() {
    this.removeEventListener()
    Mousetrap.reset()
  }

  setFocusOnFirstSuggestion = () => {
    if (!this.suggestionContainerRef) return
    const suggestionNode = this.getFirstSuggestionNode(this.suggestionContainerRef, 1)
    if (!suggestionNode) return
    suggestionNode.focus()
    setTimeout(() => {
      this.suggestionContainerRef.scrollTop = 0
    }, 50)
  }

  getLastSuggestionNode = ({ childNodes }) =>
    _.findLast(childNodes, child => child.hasAttribute('tabIndex'))

  setFocusOnLastSuggestion = () => {
    if (!this.suggestionContainerRef) return
    const suggestionNode = this.getLastSuggestionNode(this.suggestionContainerRef)
    if (!suggestionNode) return
    suggestionNode.focus()
  }

  getFirstSuggestionNode = ({ childNodes }, fromIndex) =>
    _.find(childNodes, child => child.hasAttribute('tabIndex'), fromIndex)

  getSuggestions() {
    const inputValue = String(this.state.value).trim()
    if (inputValue.length === 0) return this.props.suggestions
    return this.filterSuggestions(inputValue)
  }

  getSuggetsionContainerPosition() {
    const { bottom, top } = this.props.commentTextFieldRef.current
      ? this.props.commentTextFieldRef.current.getBoundingClientRect()
      : {}

    const bottomHeight = window.innerHeight / 2
    const heightVal = bottomHeight < 450 ? bottomHeight : 450
    const mid = (top + bottom) / 2
    if (mid < bottomHeight) {
      return {
        width: `100%`,
        top: `10px`,
        height: `${heightVal}px`,
        bottom: 'auto',
      }
    }

    return {
      width: `100%`,
      bottom: `50px`,
      height: `${heightVal}px`,
      top: 'auto',
    }
  }

  selectFirstSuggestion = () => {
    if (this.state.value === '') return
    const firstSuggestion = this.getSuggestions()[0]
    if (!firstSuggestion) return
    if (isSuggestionPTO(firstSuggestion) && !this.props.isConnected) return
    this.handleSuggestionSelected(firstSuggestion)
  }

  // eslint-disable-next-line react/sort-comp
  handleInputKeyDown = ({ nativeEvent: { code } }) => {
    const { visible } = this.state
    if (code === keysCodes.enter && visible) {
      this.selectFirstSuggestion()
      return
    }
    if (code === keysCodes.enter && this.state.value === '') {
      this.showSuggestions()
      return
    }
    if (code === keysCodes.down && visible) {
      this.setFocusOnFirstSuggestion()
      return
    }
    if (code === keysCodes.up && visible) {
      this.setFocusOnLastSuggestion()
    }
  }

  setFocusOnInput = () => {
    const inputRef = _.get(this.props, 'commentTextFieldRef.current', null)
    if (inputRef) {
      inputRef.focus()
    }
  }

  clearCurrentEditableEntryProject = () => {
    this.setState({ value: '' })
    this.props.clearCurrentEditableEntryProject(() => this.setFocusOnInput())
  }

  handleClick = ({ target }) => {
    if (!target.select) return
    target.select()
  }

  renderInput = () => {
    const { name, error, placeholder, inputProps, InputProps, inputLabelProps } = this.props
    return (
      <TextField
        inputRef={this.props.commentTextFieldRef}
        id="suggestion-input"
        data-testid="entryBox.projectSelector"
        label={error ? `${name} is required` : name}
        fullWidth
        //InputLabelProps={inputLabelProps}
        style={{color:'yellow'}}
        InputLabelProps={{
          style: { color: '#A2A2FF' },
        }}
        InputProps={{
          placeholder,
          error,
          style: { color: '#A2A2FF' },
          value: this.state.value,
          autoFocus: true,
          onKeyDown: this.handleInputKeyDown,
          onChange: this.handleInputChange,
          endAdornment: this.renderExpandIcon(),
          startAdornment: this.state.value && (
            <ClearButton onClick={this.clearCurrentEditableEntryProject} />
          ),
          ...InputProps,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          ...inputProps,
          autoComplete: 'off',
          onClick: this.showSuggestions,
          onFocus: event => event.target.select(),
        }}
        onClick={this.handleClick}
      />
    )
  }

  addOrRemoveEventListener = willShowSuggestions => {
    if (willShowSuggestions) {
      this.addEventListener()
      return
    }
    this.removeEventListener()
  }

  toggleSuggestionsVisibility = () => {
    const nextVisibility = !this.state.visible
    this.addOrRemoveEventListener(nextVisibility)
    this.setState({ visible: nextVisibility })
  }

  showSuggestions = () => {
    if (!this.state.visible) this.toggleSuggestionsVisibility()
  }

  hideSuggestions = () => {
    if (this.state.visible) this.toggleSuggestionsVisibility()
  }

  handleSuggestionSelected = suggestion => {
    this.props.onSelectSuggestion(suggestion)
    this.setState({
      value: suggestion.name,
    })
    this.hideSuggestions()
    setTimeout(() => this.props.focusCommentTextField())
  }

  renderExpandIcon = () => {
    const iconStyle = { fontSize: 20, color: '#999' }
    return (
      <InputAdornment position="end">
        {this.props.endAdornment}
        <ExpandIconWrapper onClick={this.toggleSuggestionsVisibility}>
          {this.state.visible ? <ExpandLess style={iconStyle} /> : <ExpandMore style={iconStyle} />}
        </ExpandIconWrapper>
      </InputAdornment>
    )
  }

  handleInputChange = ({ target: { value } }) => {
    this.showSuggestions()
    if (
      value.length &&
      this.suggestionContainerRef &&
      this.suggestionContainerRef.scrollTop !== 0
    ) {
      this.suggestionContainerRef.scrollTop = 0
    }
    this.setState({ value })
    if (value === '') this.props.clearCurrentEditableEntryProject()
  }

  isClickedElementOutsideOfContainer = e => !this.containerRef.current.contains(e.target)

  // close suggestions by outside clicks
  handleDocumentClick = e => {
    if (!this.containerRef.current) {
      this.removeEventListener()
      return
    }

    if (this.isClickedElementOutsideOfContainer(e)) {
      this.hideSuggestions()
    }
  }

  filterSuggestions = searchValue => {
    const { suggestions, fieldsToSearch } = this.props
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: fieldsToSearch,
    }
    const fuse = new Fuse(suggestions, options)
    return fuse.search(searchValue)
  }

  addEventListener = () => {
    document.addEventListener('click', this.handleDocumentClick, false)
    document.addEventListener('wheel', this.scrollingHideSuggestions)
  }

  removeEventListener = () => {
    document.removeEventListener('click', this.handleDocumentClick, false)
    document.removeEventListener('wheel', this.scrollingHideSuggestions)
  }

  scrollingHideSuggestions = e => {
    if (_.isNil(this.suggestionContainerRef)) return
    const closest = e.target.closest(`#suggestion-list`)
    this.setState({ visible: this.suggestionContainerRef.isSameNode(closest) })
  }

  handleSuggestionFocus = ({ target }) => {
    this.suggestionContainerRef.scrollTop = target.offsetTop <= 0 ? -40 : target.offsetTop - 40
  }

  renderSuggestionsList() {
    return (
      <SuggestionList
        visible={this.state.visible}
        suggestionRef={el => {
          this.suggestionContainerRef = el
        }}
        onFavoriteSuggestionSelected={this.props.onFavoriteSuggestionSelected}
        setFocusOnInput={() => this.setFocusOnInput()}
        handleSuggestionSelected={this.handleSuggestionSelected}
        toggleSuggestionsVisibility={this.toggleSuggestionsVisibility}
        suggestions={this.getSuggestions()}
        suggestionContainerPosition={this.getSuggetsionContainerPosition()}
        shouldRenderGroupedSuggestions={this.state.value.length < 1}
        userSettings={this.props.userSettings}
        assignments={this.props.assignments}
        mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
        handleSuggestionFocus={this.handleSuggestionFocus}
        isConnected={this.props.isConnected}
      />
    )
  }

  render() {
    return (
      <Container ref={this.containerRef}>
        {this.renderInput()}
        {this.renderSuggestionsList()}
      </Container>
    )
  }
}

InputAutoSuggestion.propTypes = {
  suggestions: PropTypes.array.isRequired,
  mostRecentlyUsedProjectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldsToSearch: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelectSuggestion: PropTypes.func.isRequired,
  error: PropTypes.bool,
  inputProps: PropTypes.object.isRequired,
  InputProps: PropTypes.object.isRequired,
  inputLabelProps: PropTypes.object.isRequired,
  onFavoriteSuggestionSelected: PropTypes.func.isRequired,
  clearCurrentEditableEntryProject: PropTypes.func.isRequired,
  commentTextFieldRef: PropTypes.object.isRequired,
  // it is being used but lodash _.get tricks the linter
  // eslint-disable-next-line react/no-unused-prop-types
  entry: PropTypes.object.isRequired,
  isConnected: PropTypes.bool.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  assignments: PropTypes.array.isRequired,
  endAdornment: PropTypes.node,
  focusCommentTextField: PropTypes.func,
}

InputAutoSuggestion.defaultProps = {
  error: false,
}

export default InputAutoSuggestion
